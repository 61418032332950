<template>
    <div>
        <article class="default_box_content bg_white p15" v-if="preloader">
            <b-row class="m-0">
                <b-col cols="12" class="user_head ">
                    <div class="head_userpic">
                        <div class="ads_icon userpic" :style="'background-image: url('+ (this.users[item.user_id].photo_250 ? this.users[item.user_id].photo_250 : '/images/icon/new_icon/user.svg')+')'"></div>
                    </div>
                    <p class="ads_name_header">
                        <span class="ads_name">{{users[item.user_id].name}} {{users[item.user_id].f_name}}</span>
                    </p>
                    <p class="ads_name_info">
                        <span v-if="$moment(item.created_at).isBefore($moment().subtract(5, 'days'))" class="ads_comment_date" v-html="$moment(item.created_at).format('DD.MM.YYYY HH:mm')"></span>
                        <span v-else class="ads_comment_date" v-html="$moment(item.created_at).startOf().fromNow()"></span>
                    </p>
                    <b-button class="del_comment_btn" v-if="$user && item.user_id == $user.id" @click="del(item.id)">
                        <font-awesome-icon :icon="['fa', 'times']" class="news_date_icon" />
                    </b-button>
                </b-col>
                <b-col cols="12">
                    <p class="ads_comment mb-3" v-html="$htmContext(item.text)"></p>
                    <imageLoader v-if="item.image" :src="item.image" :align="false"></imageLoader>
                </b-col>
                <b-col cols="12 mt-3">
                    <router-link class="ads_comments d-flex" :to="{name:'AdsGet',params: { id:item.id }}">
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 511.096 511.096" height="20px" viewBox="0 0 511.096 511.096" width="20px">
                            <g>
                                <g id="Speech_Bubble_48_">
                                    <g>
                                        <path d="m74.414 480.548h-36.214l25.607-25.607c13.807-13.807 22.429-31.765 24.747-51.246-59.127-38.802-88.554-95.014-88.554-153.944 0-108.719 99.923-219.203 256.414-219.203 165.785 0 254.682 101.666 254.682 209.678 0 108.724-89.836 210.322-254.682 210.322-28.877 0-59.01-3.855-85.913-10.928-25.467 26.121-59.973 40.928-96.087 40.928z" data-original="#000000" class="active-path" data-old_color="#000000" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div class="comment_size">{{item.comments_count}}</div>
                    </router-link>
                </b-col>
            </b-row>
        </article>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
/*eslint no-useless-escape: "error"*/
import imageLoader from "@/components/ImageLoader/imageLoader";
export default {
    name: "AdsListCard",
    props: {
        item: Object,
        users: Object,
    },
    components: {
        imageLoader
    },
    data() {
        return {
            preloader: true,
        }
    },
    methods: {
        del(id) {
            if (confirm('Вы уверены, что хотите удалить это объявление?\nЭто действие нельзя отменить')) {
                this.preloader = false;
                let url = this.$config.api_url + this.$route.params.city + '/Ads.hide/' + id;
                this.$http.post(url).then((response) => {
                    if (response.data.status === 'success') {
                        this.$parent.api();
                    }
                }).finally(() => {
                    this.preloader = true;
                })
            }

        },
    },
    mounted() {
    }
}
</script>
<style scoped>
silentbox-single {
    text-decoration: none;
}

@media only screen and (max-width: 767px) {
    .ads_icon {
        width: 40px;
        height: 40px;
    }

    .ads_name_header {
        font-size: 14px;

    }
}

@media only screen and (min-width: 576px) {
    .item {
        padding: 0 16px 16px 16px;
    }
}

@media only screen and (min-width: 767px) {
    .ads_icon {
        width: 50px;
        height: 50px;
    }

    .ads_name_header {
        font-size: 18px;

    }
}


.user_head {
    text-align: left;
    position: relative;
    z-index: 1;
    min-height: 77px;
    padding: 16px 18px 0 70px;

}

.head_userpic {
    position: absolute;
    top: 16px;
    left: 16px;
    text-decoration: none;
    font: 0/0 a;
}

.ads_icon {
    border-radius: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: #fff;
    background-position: 50%;
    background-size: cover;
    font-size: 0;

}

.ads_icon:before {
    box-shadow: none;

}

.userpic:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, .5);
    border-radius: 100%;
}

.ads_name_header {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin-right: 70px;
    line-height: 20px;
    margin-left: 7px;
    margin-bottom: 0 !important;
}

.ads_name {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    font-weight: 500;
}

.ads_name_info {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;
    color: #939cb0;
    margin-right: 70px;
    font-size: 14px;
    line-height: 20px;
}

.ads_comment_date {

    font-size: 11px;
    color: #5d5d5f;
}

.ads_comments {
    float: right;
    color: #2c3e50;
    text-decoration: none;
    align-items: center;
}

.ads_comments:hover {
    color: #4a76a8;
}

.del_comment_btn {
    position: absolute;
    right: 16px;
    top: 16px;
    color: #2c3e50;
    background: transparent;
    border: none;
}

.del_comment_btn:hover {
    color: red;
    background: transparent;
    border: none;
}

.del_comment_btn:active {
    color: red;
    background: transparent;
    border-color: red;
}

.del_comment_btn:focus {
    color: red;
    background: transparent;

}

.del_comment_btn:not(:disabled):not(.disabled):active {
    color: red;
    background: transparent;
    border-color: red;
}


.ads_comment {
    word-wrap: break-word !important;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}

.icon {
    height: 20px;

}

.comment_size {
    color: #a9b0c0;
    font-size: 18px;
    margin-left: 5px;
}

.ads_comments:hover .comment_size {
    color: #4a76a8;
}

.ads_comments svg {
    fill: #A9B0C0;
}

.ads_comments:hover svg {
    fill: #4a76a8;
}
</style>