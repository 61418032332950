<template>
    <div>
        <div class="default_box" v-if="preloader">
            <div class="default_header" v-if="this_category">
                <h1 v-if="this_category.name">Объявления - {{this_category.name}}</h1>
                <h1 v-else>Объявления</h1>
            </div>
            <button class="button button_default mb-20" v-b-modal.modal>Добавить объявление</button>
            <b-modal ref="modal" id="modal" centered title="Добавить запись" hide-footer size="lg" body-class="p-15">
                <div  v-if="$user">
                    <AdsListForm :category="this_category" ref="user_form"  @onChange="api(); hideModal()"/>
                </div>
                <div v-else>
                    <UserLogin :route="$route.fullPath" class="text-center" />
                </div>
            </b-modal>
            <div class="default_box_content" v-if="list && list.length">
                <div v-for="(item, index) in list" :key="index">
                    <AdsListCard :item="item" :users="users" ref="user_card" />
                    <AdsPlaginNew v-if="index !== 0 && index % 3 === 0" :direction="'horizontal'" style="margin-bottom: 20px;" />
                </div>
                <div v-if="button_preloader">
                    <button v-if="page < totalPages" class="load_next_button" @click="api_next()">Показать ещё</button>
                </div>
                <div v-else>
                    <PreLoadBox />
                </div>
            </div>
            <div v-else class="default_box_content bg_white p15">
                В данной рубрике пока нет объявлений
            </div>
        </div>
        <div v-else>
            <PreLoadBox />
        </div>
    </div>
</template>
<script>
import AdsListCard from "./Card";
import AdsListForm from "./Form";
import UserLogin from '@/components/App/Login/Login';

export default {
    name: "AdsList",
    components: {
        AdsListForm,
        AdsListCard,
        UserLogin,
    },
    metaInfo() {
        return {
            title: this.this_category && this.this_category.name ? `Объявления ${this.this_category.name} ` : (this.list && this.list.length ? `Объявления ` : null),
            meta: [
                { name: 'description', itemprop: 'description', content: this.this_category.name ? `Объявления ${this.this_category.name} . В городе ${this.$myCity.name}.` : '' },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: this.this_category.name ? `Объявления ${this.this_category.name} - ${this.$myCity.name}` : '' },
                { property: 'og:description', content: this.this_category.name ? `Объявления ${this.this_category.name} . В городе ${this.$myCity.name}.` : '' },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
            ]
        }
    },
    data() {
        return {
            preloader: false,
            button_preloader: true,
            page: 1,
            pageSize: 20,
            totalPages: null,
            list: [],
            this_category: {},
            users: {}
        }
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_url + this.$route.params.city + '/Ads.getList/' + this.$route.params.id;
            this.$http.get(url).then((response) => {
                this.totalPages = response.data.response.link.total_pages;
                this.list = response.data.response.item_list;
                this.this_category = response.data.response.this_category;
                this.users = response.data.response.users;
            }).finally(() => {
                this.preloader = true;
            })
        },
        api_next() {
            this.page++;
            this.button_preloader = false;
            let url = this.$config.api_url + this.$route.params.city + '/Ads.getList/' + this.$route.params.id;
            this.$http.get(url, {
                params: {
                    page: this.page
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.response.item_list);
            }).finally(() => {
                this.button_preloader = true;
            })
        },
        hideModal() {
            this.$refs['modal'].hide();
        },
    },
    mounted() {
        this.api();
    },
    watch: {
        '$pageUpdate': function() {
            this.api();
        }
    },
}
</script>
<style scoped>
@media only screen and (max-width: 991px) {
    .right_menu {
        display: none;
    }
}

.con a {
    text-decoration: none;

}

.adslist_back {
    width: 75px;
    padding: 5px 5px;
    position: relative;
    background: #4a76a8;
    border-radius: 0.25rem;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
    color: #fff;
}

.adslist_back:before {
    content: "";
    border: solid transparent;
    position: absolute;
    top: 5px;
    right: 100%;
    border-right-color: #4a76a8;
    border-width: 7px;
    margin-right: -1px;
}

.adslist_back a {
    text-decoration: none;
    color: #fff;
}

.adslist_header {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px 0;
    text-transform: uppercase;
    margin-bottom: 15px;

}

.login {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.5rem;
    margin-bottom: 10px;
}

.main_plagin {
    margin-top: 24px;
}

.ads_item {
    padding: 15px;
    background: #fff;
}

.default_box_content {
    margin-top: 20px;
}
</style>