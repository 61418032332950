<template>
    <div>
        <div v-if="category.id" class="p15">
            <b-col class="mb-2">
                <div v-if="preloader">
                    <div v-if="form.file" class="img_preloader">
                        <b-img :src="img" alt="Предзагрузка фото" />
                        <b-button class="btn-del btn-danger" @click="form.file = null">X</b-button>
                    </div>
                    <b-row>
                        <b-col class="input_box p-0">
                            <div class="upload-btn-wrapper">
                                <b-form-file v-model="form.file" placeholder="" drop-placeholder="" accept="image/jpeg, image/png" plain class="file-upload" />
                                <button class="btn_file"><img class="file_img" alt="Фото" src="/images/icon/paperclip.svg" /></button>
                            </div>
                            <textarea v-model="form.text" placeholder="" min-rows="3" max-rows="6" class="textarea_ads col form-control" required />
                            <div class="btn-send_box">
                                <img class="btn-send" alt="Отправить" src="/images/icon/send.svg" @click="send" :disabled="button.disabled"/>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <PreLoadBox />
                </div>
            </b-col>                
        </div>
    </div>
</template>
<script>


    export default {
        name: "AdsListForm",
        props: {
            category: Object
        },

        data() {
            return {
                preloader: true,
                form: {
                    file: null,
                    text: '',
                },
                img: null,
                button: {
                    disabled: false,
                    text: 'Send',
                },
            }
        },
        methods: {
            send() {
                this.preloader = false;
                let url = this.$config.api_url + this.$route.params.city + '/Ads.post';
                let form = new FormData();
                form.set('post', this.form.text);
                form.set('category_id', this.category.id);
                if (this.form.file) form.set('pic', this.form.file);
                this.button.disabled = true;
                this.button.text = 'Sending';
                this.$http.post(url, form).then(() => {
                    this.formReset();
                    this.$emit('onChange');
                }).finally(() => {
                    this.preloader = true;
                    this.button.disabled = false;
                    this.button.text = 'Send';
                });
            },
            formReset() {
                this.form.file = null;
                this.form.text = '';
            },
        },
        watch: {
            'form.file': function () {
                if (this.form.file) {
                    let image = new Image();
                    image.src = URL.createObjectURL(this.form.file);
                    image.onload = () => {
                        if (image.width > 149 && image.height > 149) {
                            this.img = URL.createObjectURL(this.form.file);
                        } else {
                            this.form.file = null;
                            alert('Минимальный размер картинки 150х150 пикселей');
                        }

                    }
                }
            },
        },
    }
</script>

<style scoped>

    .commentFile {
        position: absolute;
        top: 7px;
        left: 8px;
        color: #4a76a8;
        max-height: 10px;

    }

    .img_preloader {
        position: relative;
        display: flex;
        margin-bottom: 5px;
    }

    .img_preloader button {
        height: 100px;
    }

    .img_preloader img {
        max-height: 100px;
        max-width: 250px;
    }

    .btn-send_box {
        width: 45px;
        height: 45px;
        border: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
    }

    .btn-send {
        height: 30px;
        width: 30px;
        background: #fff;
    }

    .upload-btn-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 2;

    }

    .btn_file {
        width: 45px;
        height: 45px;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        background: transparent;
    }

    .file_img {
        height: 30px;
        width: 30px;
        background: #fff;
    }

    .btn-del {
        height: 100%;
    }

    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
    }

    .textarea_ads {
        min-height: 100px;
        padding-left: 50px;
        padding-right: 50px;
    }
</style>