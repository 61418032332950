<template>
    <div class="default_header">
        <h1>Авторизация</h1>
    </div>
</template>
<script>
export default {
    name: "Title",
    components: {},

    metaInfo() {
        return {
            title: `Авторизация - ${this.$myCity.name}`,
            meta: [
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: `Авторизация - ${this.$myCity.name}` },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },

}
</script>
<style scoped>
</style>